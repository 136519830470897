import { lazy, Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import LoadingScreen from "components/LoadingScreen"
import { ErrorBoundary } from "react-error-boundary"
import ErrorHandler from "utils/ErrorHandler"

const Landing = lazy(() => import("views/Landing"))
const OrderView = lazy(() => import("views/OrderView"))

function Router() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route
          path="/"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <Landing />
            </ErrorBoundary>
          }
        />
        <Route
          path="/:id"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <Landing />
            </ErrorBoundary>
          }
        />
        <Route
          path="/orderview"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <OrderView />
            </ErrorBoundary>
          }
        />
      </Routes>
    </Suspense>
  )
}
export default Router
