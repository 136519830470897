import { useContext } from "react"
import { UserContext } from "contexts/UserContext"

export default function useUser() {
  const {
    settings,
    saveSettings,
    setStyles,
    loading,
    translations,
    selectedLanguage,
    setSelectedLanguage,
  } = useContext(UserContext)

  const setLightMode = (lightMode) => {
    saveSettings("lightMode", lightMode)
    setStyles(lightMode)
  }

  return {
    settings,
    saveS: saveSettings,
    setLightMode,
    loading,
    translations,
    selectedLanguage,
    setSelectedLanguage,
  }
}
