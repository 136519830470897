import axios from "axios"

var axiosGlobal = axios.create({
  timeout: 15000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

export const dictionaryGlobal = {
  getTranslations: async (language) => {
    try {
      const axiosconfig = {
        params: {
          app: "barsoftMe",
          language,
        },
      }
      const response = await axiosGlobal.get(
        `https://ipanel.okeoke.io/api/v1/dictionary/global`,
        axiosconfig
      )
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
  addTranslation: async (token, key) => {
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post(`https://ipanel.okeoke.io/api/v1/dictionary/global/new`, {
          app: "barsoftMe",
          key,
        })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
}
