import { useState, createContext, useEffect } from "react"
import { dictionaryGlobal } from "apis/dictionary/global"

const UserContext = createContext()

const UserProvider = (props) => {
  const [settings, setSettings] = useState(null)
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage["barsoftME-selectedLanguage"] || "hu"
  )
  const [translations, setTranslations] = useState(
    JSON.parse(localStorage["barsoftME-translations"] || "{}")
  )
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    initUserSettings()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dictionaryGlobal.getTranslations(selectedLanguage).then((res) => {
      if (res.success) {
        let obj = {}
        for (let item of res.data) {
          obj[item.key] = item[selectedLanguage]
        }
        localStorage["barsoftME-selectedLanguage"] = selectedLanguage
        localStorage["barsoftME-translations"] = JSON.stringify(obj)
        setTranslations(obj)
      } else {
        console.log(res)
      }
    })
  }, [selectedLanguage])

  const initUserSettings = () => {
    if (localStorage["barsoftME-userSettings"] != null) {
      try {
        let data = JSON.parse(localStorage["barsoftME-userSettings"] || {})
        setSettings(data)
        setStyles(data.lightMode)
      } catch (e) {
        console.log(e)
        setStyles()
      }
      setTimeout(() => {
        setLoading(false)
      }, 300)
    } else {
      setStyles()
      setTimeout(() => {
        setLoading(false)
      }, 300)
    }
  }

  const saveSettings = (key, value) => {
    let newValues = { ...settings, [key]: value }
    localStorage["barsoftME-userSettings"] = JSON.stringify(newValues)
    setSettings(newValues)
  }

  const addStyle = (name, value) => {
    document.documentElement.style.setProperty(name, value)
  }

  const setStyles = (lightMode) => {
    addStyle("--ten-color", "#2E9BD9")
    addStyle("--ten-color-80", "#58AFE1")
    addStyle("--ten-color-60", "#82C3E8")
    if (!lightMode) {
      addStyle("--sixty-color", "#1F2023")
      addStyle("--thirty-color", "#27282C")
      addStyle("--text-color", "#ECEFF1")
      addStyle("--border-color", "#383940")
    } else {
      addStyle("--sixty-color", "#F5F5F5")
      addStyle("--thirty-color", "#FAFAFA")
      addStyle("--text-color", "#000000")
      addStyle("--border-color", "#D9D9D9")
    }
  }

  return (
    <UserContext.Provider
      value={{
        settings,
        saveSettings,
        setStyles,
        setSelectedLanguage,
        selectedLanguage,
        translations,
        loading,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export { UserContext, UserProvider }
