import {Button} from '@nextui-org/react';
import Icon from 'components/icons';
import useEntity from 'hooks/useEntity';
import useMultilang from 'hooks/useMultilang';
import useSvg from 'hooks/useSvg';
import {useNavigate} from 'react-router-dom';

function TopBar() {
  const {getSvg} = useSvg();
  const {getT} = useMultilang();
  const {setEntityId, selectedOrder, selectedExternalPayment} = useEntity();
  const navigate = useNavigate();

  const handleResetId = () => {
    navigate('/');
    setEntityId(null);
  };

  return (
    <div className='w-full flex items-center justify-between py-2 px-2 '>
      <div className='w-32 h-16' onClick={() => window.open('https://barsoft.hu')}>
        <Icon name='barsoft' className='fill-current' />
      </div>
      {(selectedOrder || selectedExternalPayment) && <Button onClick={() => handleResetId()}>{getT('invoice.resetId')}</Button>}
    </div>
  );
}
export default TopBar;
