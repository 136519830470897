import {Button, ButtonGroup} from '@nextui-org/react';
import Icon from 'components/icons';
import useSvg from 'hooks/useSvg';
import useUser from 'hooks/useUser';
import {useTheme} from 'next-themes';

function LightModeSwitch() {
  const {settings, setLightMode} = useUser();
  const {getSvg} = useSvg();
  const {theme, setTheme} = useTheme();
  return (
    <ButtonGroup>
      <Button color={theme === 'light' ? 'primary' : 'default'} onClick={() => setTheme('light')}>
        <div className='w-6 h-6'>
          <Icon name='sun' className={theme === 'light' ? 'stroke-warning' : 'stroke-foreground'} />
        </div>
      </Button>
      <Button color={theme === 'dark' ? 'primary' : 'default'} onClick={() => setTheme('dark')}>
        <div className='w-6 h-6'>
          <Icon name='moon' className={theme === 'dark' ? 'stroke-foreground' : 'stroke-foreground'} />
        </div>
      </Button>
    </ButtonGroup>
  );
}
export default LightModeSwitch;
