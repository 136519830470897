import {NextUIProvider} from '@nextui-org/react';
import LightModeSwitch from 'components/LightModeSwitch';
import TopBar from 'components/TopBar';
import UserLanguages from 'components/UserLanguages';
import {EntityProvider} from 'contexts/EntityContext';
import {ThemeProvider as NextThemesProvider} from 'next-themes';
import {Toaster} from 'react-hot-toast';
import 'style.css';
import Router from 'utils/Router';
import {UserProvider} from './contexts/UserContext';

function App() {
  return (
    <NextUIProvider>
      <NextThemesProvider themes={['dark', 'light']} attribute='class' defaultTheme='dark'>
        <Toaster position='top-center' reverseOrder={false} />

        <UserProvider>
          <EntityProvider>
            <div className='w-[100vw] h-[100vh] overflow-hidden flex flex-col'>
              <TopBar />
              <div className='grow overflow-hidden flex flex-col'>
                <Router />
              </div>
              <div className='flex flex-row justify-between items-center px-4 pb-2'>
                <UserLanguages />
                <LightModeSwitch />
              </div>
            </div>
          </EntityProvider>
        </UserProvider>
      </NextThemesProvider>
    </NextUIProvider>
  );
}
export default App;
