import useUser from "hooks/useUser"
import { dictionaryGlobal } from "apis/dictionary/global"

export default function useMultilang() {
  const { selectedLanguage, translations, setSelectedLanguage } = useUser()

  const getT = (key) => {
    let token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiRTRCNEVGNjMzNUYxNDRGMDlEMjM5Q0NBMkE0RURFMTIiLCJpYXQiOjE2OTIxNzE5NzgsImV4cCI6MTY5Mzk4NjM3OH0.Oo0koPlpU_PvgOi1EO8qHsFB7RicHx5a0aD9YTFaPI0"
    if (
      key != null &&
      process.env.NODE_ENV === "development" &&
      translations?.[key] == null
    ) {
      dictionaryGlobal.addTranslation(token, key).then((res) => {
        if (res.success) {
          console.log(res)
        } else {
          console.log(res)
        }
      })
    }
    return translations?.[key] && translations?.[key] !== ""
      ? translations?.[key]
      : key
  }

  return {
    language: selectedLanguage,
    translations,
    getT,
    setLanguage: setSelectedLanguage,
  }
}
