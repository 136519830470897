import {Button} from '@nextui-org/react';
import Icon from 'components/icons';
import useMultilang from 'hooks/useMultilang';
import useUser from 'hooks/useUser';

function UserLanguages() {
  const {setLanguage, language} = useMultilang();
  const {settings} = useUser();

  return (
    <div className={`flex flex-row gap-2 ${settings?.sidebarClosed ? 'small' : ''}`}>
      <Button isIconOnly onPress={() => setLanguage('hu')}>
        <div className='w-6 h-6'>
          <Icon name='hungary' className='fill-current' />
        </div>
      </Button>
      <Button isIconOnly onPress={() => setLanguage('en')}>
        <div className='w-6 h-6'>
          <Icon name='english' className='fill-current' />
        </div>
      </Button>
    </div>
  );
}
export default UserLanguages;
