import axios from "axios"

export const brandCodes = {
  getEntityByCode: async (id) => {
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .get(`https://ipanel.barsoft.hu/api/v1/codes/id/${id.toLowerCase()}`)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  },
}
