import { useContext } from "react"
import { EntityContext } from "contexts/EntityContext"

export default function useEntity() {
  const {
    selectedOrder,
    selectedExternalPayment,
    setEntityId,
    entityId,
    loading,
  } = useContext(EntityContext)

  return {
    selectedOrder,
    selectedExternalPayment,
    setEntityId,
    entityId,
    loading,
  }
}
