import { ReactComponent as Menu } from "svg/menu.svg"
import { ReactComponent as EnFlag } from "svg/en.svg"
import { ReactComponent as HuFlag } from "svg/hu.svg"
import { ReactComponent as Exit } from "svg/exit.svg"
import { ReactComponent as LightDark } from "svg/mdi_theme-light-dark.svg"
import { ReactComponent as Save } from "svg/material-symbols_save.svg"
import { ReactComponent as Cross } from "svg/cross.svg"
import { ReactComponent as Plus } from "svg/charm_plus.svg"
import { ReactComponent as Sun } from "svg/sun.svg"
import { ReactComponent as SunL } from "svg/sun_l.svg"
import { ReactComponent as Moon } from "svg/moon.svg"
import { ReactComponent as MoonL } from "svg/moon_l.svg"
import { ReactComponent as Alert } from "svg/alert.svg"
import { ReactComponent as Check } from "svg/check.svg"
import { ReactComponent as MoreHorizontal } from "svg/more-horizontal.svg"
import { ReactComponent as UploadCloud } from "svg/upload-cloud.svg"
import { ReactComponent as Reload } from "svg/reload.svg"
import { ReactComponent as Filter } from "svg/filter.svg"
import { ReactComponent as Chevron } from "svg/chevron.svg"
import { ReactComponent as BarsoftLogo } from "svg/barsoft_logo.svg"

export default function useSvg() {
  const getSvg = (name, onClick) => {
    const svgObject = {
      menu: <Menu />,
      en: <EnFlag />,
      hu: <HuFlag />,
      exit: <Exit />,
      lightDark: <LightDark />,
      save: <Save />,
      cross: <Cross />,
      plus: <Plus />,
      sun: <Sun />,
      sunL: <SunL />,
      moon: <Moon />,
      moonL: <MoonL />,
      alert: <Alert />,
      check: <Check />,
      moreHorizontal: <MoreHorizontal />,
      uploadCloud: <UploadCloud />,
      reload: <Reload />,
      filter: <Filter />,
      chevron: <Chevron />,
      barsoftLogo: <BarsoftLogo />,
    }

    const handleClick = (e) => {
      if (typeof onClick === "function") onClick(e)
    }

    return (
      <div className="svg-root" onClick={handleClick}>
        {svgObject?.[name]}
      </div>
    )
  }

  return {
    getSvg,
  }
}
