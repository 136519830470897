import {brandCodes} from 'apis/codes/codes';
import useMultilang from 'hooks/useMultilang';
import {createContext, useEffect, useState} from 'react';
import toast from 'react-hot-toast';

const EntityContext = createContext();

const EntityProvider = (props) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedExternalPayment, setSelectedExternalPayment] = useState(null);
  const [entityId, setEntityId] = useState(null);
  const [loading, setLoading] = useState(false);
  const {getT} = useMultilang();

  useEffect(() => {
    if (entityId != null) {
      setLoading(true);
      brandCodes
        .getEntityByCode(entityId)
        .then((res) => {
          if (res.success) {
            console.log(res);
            if (res.data.refType === 0) setSelectedOrder(res.data.data);
            if (res.data.refType === 1) setSelectedExternalPayment(res.data.data);
          } else {
            console.log(res);
            toast.error(getT('entity.response.error'));
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
        });
    } else {
      setSelectedOrder(null);
      setSelectedExternalPayment(null);
    }
    // eslint-disable-next-line
  }, [entityId]);

  return (
    <EntityContext.Provider
      value={{
        selectedOrder,
        selectedExternalPayment,
        setEntityId,
        entityId,
        loading,
      }}>
      {props.children}
    </EntityContext.Provider>
  );
};

export {EntityContext, EntityProvider};
